import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  Box,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const getTextProperty = (property) => {
  switch (property) {
    case "positive":
      return "позитивных";
    case "neutral":
      return "нейтральных";
    case "negative":
      return "негативных";
    case "aggressive":
      return "агрессивных";
    case "passiveAggressive":
      return "пассивно-агрессивных";
    case "analytical":
      return "аналитических";
    case "supportive":
      return "поддерживающих";
    case "humorous":
      return "юморных";
    case "imperative":
      return "императивных";
    case "creative":
      return "творческих";
    case "reflexive":
      return "рефлексивных";
    default:
      return "";
  }
};

const getDeviationMessage = (deviants) => {
  return (
    <ul style={{ paddingLeft: "20px" }}>
      {deviants.map((deviation) => {
        const propertyText = getTextProperty(deviation.property);
        const changeDirection =
          deviation.type_deviation === "increase" ? "больше" : "меньше";
        const percentage = Math.abs(
          deviation.second_percent - deviation.first_percent
        );

        const tooltipTitle = `Медианное значение: ${deviation.first_value} (${deviation.first_percent}%), За последнюю неделю: ${deviation.second_value} (${deviation.second_percent}%)`;

        return (
          <li key={deviation._id}>
            <span style={{ fontWeight: "bold" }}>{propertyText}</span> сообщений
            стало{" "}
            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
              <Tooltip title={`${tooltipTitle}`} arrow>
                {changeDirection}{" "}
              </Tooltip>
            </span>{" "}
            на{" "}
            <Tooltip title={`${percentage}%`} arrow>
              {Math.abs(percentage)}
            </Tooltip>
            %.
          </li>
        );
      })}
    </ul>
  );
};

const getTextNotify = (notify) => {
  const entity = notify.entity_type === "employee" ? "У сотрудника" : "В чате";
  const action =
    notify.deviants.length === 1
      ? "зафиксировано отклонение"
      : "зафиксированы отклонения";
  const deviationMessage = getDeviationMessage(notify.deviants);

  const startDateLastWeek = new Date(
    notify.start_date_last_week
  ).toLocaleString();
  const endDateLastWeek = new Date(notify.end_date_last_week).toLocaleString();
  const startDateMedian = new Date(notify.start_date_median).toLocaleString();
  const endDateMedian = new Date(notify.end_date_median).toLocaleString();

  return (
    <span>
      {entity}{" "}
      {notify.entity_type === "employee" ? (
        <Tooltip title={`${notify.entity_id}`} arrow>
          <span style={{ textDecoration: "underline" }}>
            {notify.entity_name}
          </span>
        </Tooltip>
      ) : (
        notify.entity_name
      )}{" "}
      {action} за{" "}
      <Tooltip title={`(${startDateLastWeek} - ${endDateLastWeek})`} arrow>
        <span style={{ textDecoration: "underline" }}>прошедшую</span>
      </Tooltip>{" "}
      неделю от{" "}
      <Tooltip title={`(${startDateMedian} - ${endDateMedian})`} arrow>
        <span style={{ textDecoration: "underline" }}>медианных</span>
      </Tooltip>{" "}
      значений: {deviationMessage}
    </span>
  );
};
const Notifyes = ({ notifyes }) => {
  const [openNotifyId, setOpenNotifyId] = useState(null);

  const handleToggle = (id) => {
    setOpenNotifyId(openNotifyId === id ? null : id);
  };
  return (
    <div>
      <TableContainer
        component={Paper}
        style={{ maxHeight: "65vh", overflow: "auto" }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Дата</TableCell>
              <TableCell>Уведомление</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notifyes.map((notify) => (
              <React.Fragment key={notify.id}>
                <TableRow
                  hover
                  onClick={() => handleToggle(notify._id)}
                  style={{ cursor: "pointer", backgroundColor: "#f5f5f5" }}
                >
                  <TableCell>
                    {new Date(notify.time_creating).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent={"space-between"}
                    >
                      <Typography>{getTextNotify(notify)}</Typography>
                      <IconButton size="small">
                        <ExpandMoreIcon
                          style={{
                            transform:
                              openNotifyId === notify._id
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                            transition: "transform 0.2s",
                            color:
                              openNotifyId === notify._id
                                ? "#1976d2"
                                : "inherit",
                          }}
                        />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={2}
                    style={{ padding: 0 }}
                    key={notify._id}
                  >
                    <Collapse
                      in={openNotifyId === notify._id}
                      timeout="auto"
                      unmountOnExit
                      key={notify._id}
                    >
                      <Box
                        margin={1}
                        style={{
                          backgroundColor: "#e3f2fd",
                          borderRadius: "4px",
                          padding: "10px",
                        }}
                      >
                        <Typography variant="h6">
                          Проблемы и решения:
                        </Typography>
                        {notify.solutions.map((solution) => (
                          <Box key={solution._id} marginY={1}>
                            <Typography variant="subtitle1">
                              {solution.title}
                            </Typography>
                            <ul>
                              {solution.recommendations.map((rec, index) => (
                                <li key={index}>
                                  <Typography variant="body2">{rec}</Typography>
                                </li>
                              ))}
                            </ul>
                          </Box>
                        ))}
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Notifyes;
