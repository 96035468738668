import React, { useEffect, useState, useMemo } from "react";
import Modal from "react-modal";
import { ReactComponent as Close } from "../../assets/img/closeModal.svg";
import { tablesColumns } from "../../tablesColumns";
import Button from "../Button/Button";
import Report from "../Report/Report";
import Filters from "../Filters/Filters";
import DataTable from "datatables.net-react";
import DT from "datatables.net-dt";
import "datatables.net-dt/css/dataTables.dataTables.css";
import { APP_VIEWS, REPORT_VIEWS } from "../../utils/constants";
import MessageList from "../MessageList/MessageList";

import styles from "./InfoModal.module.css";
import clsx from "clsx";
import { currentDateTime, formatDateToShortString } from "../../utils/utils";
import Notifyes from "../Notifyes/Notifyes";

DataTable.use(DT);
Modal.setAppElement("#root");

const InfoModal = ({
  chats,
  viewReport,
  appView,
  setViewReport,
  onClose,
  currentChat,
  currentEmployee,
  handleDeleteChat,
  onClickButtonMessages,
  messages,
  senderFilter,
  chatNameFilter,
  emotionFilter,
  typeFilter,
  setSenderFilter,
  setChatNameFilter,
  setEmotionFilter,
  setTypeFilter,
  filteredChats,
}) => {
  const activeTable = "messages";
  const isOpen = useMemo(() => !!viewReport, [viewReport]);

  const isChat = useMemo(() => viewReport === REPORT_VIEWS.CHAT, [viewReport]);
  const isEmployee = useMemo(
    () => viewReport === REPORT_VIEWS.EMPLOYEE,
    [viewReport]
  );
  const isMessages = useMemo(
    () => viewReport === REPORT_VIEWS.MESSAGES,
    [viewReport]
  );
  const isNotifyes = useMemo(
    () => viewReport === REPORT_VIEWS.NOTIFYES,
    [viewReport]
  );

  const notifyes = useMemo(() => {
    return currentChat?.notifies ?? currentEmployee?.notifies ?? [];
  }, [currentChat, currentEmployee]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  const chatsEmployees =
    currentEmployee?.messages.reduce((acc, message) => {
      if (filteredChats.find((chat) => chat.name === message.chat)) {
        acc.add(message.chat);
      }
      return acc;
    }, new Set()) ?? [];
  return (
    <Modal
      isOpen={!!viewReport}
      onRequestClose={onClose}
      className={clsx(styles.modal, isMessages && styles.modalMessages)}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h1 className={styles.modalTitle}>
            {isChat
              ? "Чат - " + currentChat?.name ?? ""
              : isEmployee
              ? "Сотрудник - " +
                (currentEmployee?.senderName
                  ? currentEmployee?.senderName + " - "
                  : "") +
                (currentEmployee?.senderNickname
                  ? currentEmployee?.senderNickname
                  : "") +
                " (" +
                [...chatsEmployees.values()].join(", ") +
                ")"
              : "Сообщения - " +
                (currentChat?.name ?? currentEmployee?.senderName)}
          </h1>
          <div className={styles.modalClose} onClick={onClose}>
            <Close />
          </div>
        </div>

        <div
          className={clsx(
            styles.modalTabs,
            isMessages && styles.modalTabsMessages
          )}
        >
          <div className={styles.modalTabsNav}>
            <Button
              text={
                appView === APP_VIEWS.CHATS
                  ? "Отчет по чату"
                  : "Отчет по сотруднику"
              }
              type={isEmployee || isChat ? "primary" : "secondary"}
              action={() =>
                setViewReport(
                  appView === APP_VIEWS.CHATS
                    ? REPORT_VIEWS.CHAT
                    : REPORT_VIEWS.EMPLOYEE
                )
              }
            />
            <Button
              text={
                "Список сообщений" +
                (appView === APP_VIEWS.EMPLOYEES
                  ? ` (${currentEmployee?.messages?.length ?? ""})`
                  : "")
              }
              type={isMessages ? "primary" : "secondary"}
              action={() => {
                setViewReport(REPORT_VIEWS.MESSAGES);
                onClickButtonMessages(currentChat?.id ?? currentChat?._id);
              }}
              disabled={
                viewReport === REPORT_VIEWS.EMPLOYEE
                  ? currentEmployee.messages.length === 0
                  : false
              }
            />
            {
              <Button
                text={
                  "Уведомления" +
                  (notifyes.length === 0 ? "" : ` (${notifyes.length})`)
                }
                type={isNotifyes ? "primary" : "secondary"}
                action={() => {
                  setViewReport(REPORT_VIEWS.NOTIFYES);
                }}
                disabled={notifyes.length === 0}
              />
            }
            {viewReport === REPORT_VIEWS.CHAT && (
              <Button
                text="Удалить чат"
                type={"secondary"}
                action={() =>
                  handleDeleteChat(currentChat.id ?? currentChat._id)
                }
                className="removeChatButton"
              />
            )}
          </div>
          {appView === APP_VIEWS.CHATS && viewReport === REPORT_VIEWS.CHAT && (
            <h3>
              Время обновления:{" "}
              {currentDateTime(new Date(currentChat?.timeLastUpdate))}
            </h3>
          )}
          <div className={styles.modalTabsContent}>
            {isChat && <Report {...currentChat} />}
            {isEmployee && <Report {...currentEmployee} />}
            {isMessages && (
              <MessageList
                messages={messages}
                appView={appView}
                senderFilter={senderFilter}
                chatNameFilter={chatNameFilter}
                emotionFilter={emotionFilter}
                typeFilter={typeFilter}
                setSenderFilter={setSenderFilter}
                setChatNameFilter={setChatNameFilter}
                setEmotionFilter={setEmotionFilter}
                setTypeFilter={setTypeFilter}
              />
            )}
            {isNotifyes && <Notifyes notifyes={notifyes} />}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InfoModal;
