export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://lomufonfer.beget.app/api";

export const WEBSOCKET_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5001"
    : "https://lomufonfer.beget.app";

export const MESSENGERS = {
  TG: "tg",
  WA: "wa",
};

export const STATUS_CONNECT_TG = {
  NONE: "none",
  PHONE: "phone",
  CODE: "code",
  PASSWORD: "password",
};
export const getStorageNameSelectedChats = (profile_id) =>
  `selectedChats-${profile_id}`;

export const getStorageNamePhone = () => `telegram-phone`;

export const STATUSES_CHAT = {
  CREATING: "creating",
  LOADING_HISTORY: "loading_history",
  GET_EMOTION_COLORS_FROM_API: "get_emotion_colors",
  GET_GENERAL_REPORT_FROM_API: "update_main_data",
  CREATE_REPORT: "create_report",
  COMPLETED: "completed",
  ERROR: "error",
};

export const TEXTS_STATUSES = {
  creating: "Запуск генерации отчета...",
  loading_history: "Загрузка сообщений из чата...",
  get_emotion_colors: "Получение эмоционального окраса...",
  update_main_data: "Подсчет данных...",
  create_report: "Генерация отчета...",
  completed: "Готов",
  error: "Ошибка при создании отчета",
};

export const APP_VIEWS = {
  CONNECTION: "Подключение",
  CHATS: "Чаты",
  EMPLOYEES: "Сотрудники",
  ADMIN: "Админка",
};

export const REPORT_VIEWS = {
  CHAT: "Чат",
  EMPLOYEE: "Сотрудник",
  MESSAGES: "Сообщения",
  NOTIFYES: "Уведомления",
};
