import { APP_VIEWS } from "./constants";

export function calculatePercentages(values) {
  const totalSum = values.reduce((acc, value) => acc + value, 0);

  if (totalSum === 0) {
    return values.map(() => 0);
  }

  let percentages = values.map((value) => Math.round((value / totalSum) * 100));

  const sumPercentages = percentages.reduce(
    (acc, percentage) => acc + percentage,
    0
  );
  if (sumPercentages !== 100) {
    const diff = sumPercentages - 100;
    for (let i = 0; i < percentages.length; i++) {
      if (percentages[i] > Math.abs(diff)) {
        percentages[i] -= Math.sign(diff);
        break;
      }
    }
  }

  return percentages;
}

export function currentDateTime(initDate) {
  const date = new Date(initDate);
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };
  return date
    .toLocaleString("ru-RU", options)
    .replace(",", " -")
    .replace(/\//g, ".");
}
export function formatDateToShortString(dateInitial) {
  const date = dateInitial ? new Date(dateInitial) : new Date();
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Месяцы начинаются с 0
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
}
export const getEndDay = (day) => new Date(day.setHours(23, 59, 59, 999));

export function replaceValuesForMessages(arr) {
  const emotionMap = {
    neutral: "Нейтральный",
    positive: "Позитивный",
    negative: "Негативный",
  };

  const typeMap = {
    aggressive: "Агрессивный",
    analytical: "Аналитический",
    creative: "Креативный",
    humorous: "Юмористический",
    passiveAggressive: "Пассивный",
    reflexive: "Рефлексивный",
    supportive: "Поддерживающий",
    imperative: "Императивный",
  };

  return arr.map((item) => {
    return {
      ...item,
      emotionMessage: emotionMap[item.emotionMessage] || item.emotionMessage,
      typeMessage: typeMap[item.typeMessage] || item.typeMessage,
    };
  });
}

const getValueForСhats = (
  isLoading,
  value,
  total,
  medianValue,
  medianTotal,
  deviants
) => {
  const v = Number((value / total) * 100);
  const lvV = Number((medianValue / medianTotal) * 100).toFixed(0);
  const diff = lvV - Math.round(v);
  const plusMinus = lvV < v || lvV === v ? "+" : "-";

  const percent = isNaN(v) ? 0 : v.toFixed(0);
  const percentToFixed = isNaN(v) ? 0 : v.toFixed(1);
  const percentDiff = isNaN(diff) ? 0 : Math.abs(diff);
  const text = `<div title="${value} (${medianValue})">${percent}% <span>(${plusMinus}${percentDiff}%)</span></div>`;
  return {
    value,
    medianValue,
    percent,
    percentToFixed,
    plusMinus,
    percentDiff,
    text,
    deviants,
    isLoading: !!isLoading,
  };
};
export const replaceValuesForСhats = (arr, viewMode, isLoading = false) => {
  const result = arr.map((chat) => {
    const positive = getValueForСhats(
      isLoading,
      chat.positive,
      chat.totalMessages,
      chat.medianValues.positive,
      chat.medianValues.totalMessages,
      chat.deviants.filter(({ property }) => property === "positive")
    );
    const neutral = getValueForСhats(
      isLoading,
      chat.neutral,
      chat.totalMessages,
      chat.medianValues.neutral,
      chat.medianValues.totalMessages,
      chat.deviants.filter(({ property }) => property === "neutral")
    );
    const negative = getValueForСhats(
      isLoading,
      chat.negative,
      chat.totalMessages,
      chat.medianValues.negative,
      chat.medianValues.totalMessages,
      chat.deviants.filter(({ property }) => property === "negative")
    );
    const aggressive = getValueForСhats(
      isLoading,
      chat.aggressive,
      chat.totalMessages,
      chat.medianValues.aggressive,
      chat.medianValues.totalMessages,
      chat.deviants.filter(({ property }) => property === "aggressive")
    );
    const analytical = getValueForСhats(
      isLoading,
      chat.analytical,
      chat.totalMessages,
      chat.medianValues.analytical,
      chat.medianValues.totalMessages,
      chat.deviants.filter(({ property }) => property === "analytical")
    );
    const creative = getValueForСhats(
      isLoading,
      chat.creative,
      chat.totalMessages,
      chat.medianValues.creative,
      chat.medianValues.totalMessages,
      chat.deviants.filter(({ property }) => property === "creative")
    );
    const humorous = getValueForСhats(
      isLoading,
      chat.humorous,
      chat.totalMessages,
      chat.medianValues.humorous,
      chat.medianValues.totalMessages,
      chat.deviants.filter(({ property }) => property === "humorous")
    );
    const passiveAggressive = getValueForСhats(
      isLoading,
      chat.passiveAggressive,
      chat.totalMessages,
      chat.medianValues.passiveAggressive,
      chat.medianValues.totalMessages,
      chat.deviants.filter(({ property }) => property === "passiveAggressive")
    );
    const reflexive = getValueForСhats(
      isLoading,
      chat.reflexive,
      chat.totalMessages,
      chat.medianValues.reflexive,
      chat.medianValues.totalMessages,
      chat.deviants.filter(({ property }) => property === "reflexive")
    );
    const supportive = getValueForСhats(
      isLoading,
      chat.supportive,
      chat.totalMessages,
      chat.medianValues.supportive,
      chat.medianValues.totalMessages,
      chat.deviants.filter(({ property }) => property === "supportive")
    );
    const imperative = getValueForСhats(
      isLoading,
      chat.imperative,
      chat.totalMessages,
      chat.medianValues.imperative,
      chat.medianValues.totalMessages,
      chat.deviants.filter(({ property }) => property === "imperative")
    );

    return {
      ...chat,
      totalMessages: {
        text: `${chat.totalMessages} <span>(${chat.medianValues.totalMessages})</span>`,
        totalMessages: chat.totalMessages,
        medianTotalMessages: chat.medianValues.totalMessages,
      },
      positive: positive,
      neutral: neutral,
      negative: negative,
      aggressive: aggressive,
      analytical: analytical,
      creative: creative,
      humorous: humorous,
      passiveAggressive: passiveAggressive,
      reflexive: reflexive,
      supportive: supportive,
      imperative: imperative,
    };
  });
  return result;
};

export const handleEmployeesBeforeView = (chats, employees) => {
  const loadingChats = chats.filter((item) => item.status_chat === "creating");
  const result = employees.map((item) => {
    if (
      item.includedChats.some((includedChat) =>
        loadingChats.find((chat) => chat.id === includedChat)
      )
    ) {
      // true это показ текста "загрузка"
      return replaceValuesForСhats([item], APP_VIEWS.EMPLOYEES, true)[0];
    }
    return replaceValuesForСhats([item], APP_VIEWS.EMPLOYEES)[0];
  });
  return result;
};
