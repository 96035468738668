import React, { useEffect, useMemo, useState } from "react";
import {
  Container,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
} from "@mui/material";
import UserTable from "../UserTable/UserTable";
import UserFormModal from "../UserFormModal/UserFormModal";
import mainApi from "../../utils/MainApi";
import { Link } from "react-router-dom";

const AdminPanel = ({
  setIsLoading,
  handlerErrors,
  setNotifyError,
  setNotifyInfo,
  handleSignOut,
  getLogs,
}) => {
  const [users, setUsers] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [botTgChatIds, setBotTgChatIds] = useState([]);

  const updateUser = async (id, updatedUser) => {
    const updated = await mainApi.updateUser({
      id: id,
      name: updatedUser.name,
      email: updatedUser.email,
      password: updatedUser.password,
      profile_tg: updatedUser.profile_tg,
      profile_wa: updatedUser.profile_wa,
      access: updatedUser.access,
      chat_tg_id: updatedUser.chat_tg_id,
    });
    if (updateUser) {
      setNotifyInfo("Пользователь обновлен");
    }
    setUsers(users.map((user) => (user._id === id ? updated : user)));
  };

  const addUser = async (newUser, chatTgId) => {
    try {
      setIsLoading(true);
      const user = await mainApi.addUserFromAdmin({
        ...newUser,
        chat_tg_id: chatTgId,
      });
      if (!user.error) {
        getAllUsers();
        setNotifyInfo("Пользователь добавлен");
      } else {
        handlerErrors({ message: user.error });
      }
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteUser = (user) => {
    setUserToDelete(user);
    setConfirmOpen(true);
  };

  const confirmDeleteUser = async () => {
    try {
      setIsLoading(true);
      const user = await mainApi.deleteUser(userToDelete._id);
      if (!user.error) {
        setNotifyInfo("Пользователь удален");

        setUsers(users.filter((user) => user._id !== userToDelete._id));
      } else {
        handlerErrors({ message: user.error });
      }
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoading(false);
      setConfirmOpen(false);
      setUserToDelete(null);
    }
  };

  const getAllUsers = async () => {
    try {
      setIsLoading(true);
      const allUers = await mainApi.getAllUsers();
      if (!allUers.error) {
        setUsers(allUers);
      } else {
        setUsers([]);
      }
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoading(false);
    }
  };
  const loadBotTgChatIds = async () => {
    try {
      setIsLoading(true);
      const chats = await mainApi.loadBotTgChatIds();
      if (!chats.error) {
        setBotTgChatIds(chats);
      } else {
        setBotTgChatIds([]);
      }
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllUsers();
    loadBotTgChatIds();
  }, []);
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Панель администратора
      </Typography>
      <Link to="/">Перейти в кабинет</Link>
      <div
        style={{
          display: "flex",
          margin: "20px 0",
          gap: "15px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => setModalOpen(true)}
        >
          Добавить пользователя
        </Button>{" "}
        <Button variant="contained" color="primary" onClick={() => getLogs()}>
          Скачать логи
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleSignOut()}
        >
          Выйти
        </Button>
      </div>
      <p>
        <Tooltip title="Чтобы переписка с ботом появилась тут, надо ему написать или добавить в группу mood_monitor_bot">
          <span style={{ textDecoration: "underline", cursor: "pointer" }}>
            Надо написать боту{" "}
          </span>
        </Tooltip>
        сюда{" "}
        <a target="_blank" href="https://t.me/mood_monitor_notify_bot">
          mood_monitor_notify_bot
        </a>
      </p>
      <UserTable
        users={users}
        onUpdateUser={updateUser}
        onDeleteUser={handleDeleteUser}
        botTgChatIds={botTgChatIds ?? []}
      />
      <UserFormModal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        onAddUser={addUser}
      />

      <Dialog open={isConfirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>Подтвердите удаление</DialogTitle>
        <DialogContent>
          Вы уверены, что хотите удалить пользователя {userToDelete?.name}?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)} color="secondary">
            Отмена
          </Button>
          <Button onClick={confirmDeleteUser} color="primary">
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AdminPanel;
